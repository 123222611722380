export default Object.freeze({
  ADMIN_TOOL_TYPE_SERVICE: '서비스어드민', // common.admin.tool.type.service

  // Time Unit
  YEAR: '년',
  MONTH: '월',
  DAY: '일',
  MONTHS: '개월',
  PERIOD: '기간',
  TODAY: '오늘',
  YESTERDAY: '어제',
  CLOCK: '시',
  TIMES: '회',
  SUN: '일요일',
  MON: '월요일',
  TUE: '화요일',
  WED: '수요일',
  THU: '목요일',
  FRI: '금요일',
  SAT: '토요일',
  SUN_SHOR: '일',
  MON_SHOR: '월',
  TUE_SHOR: '화',
  WED_SHOR: '수',
  THU_SHOR: '목',
  FRI_SHOR: '금',
  SAT_SHOR: '토',
  DATA1: '1개월',
  DATA2: '3개월',
  DATA3: '6개월',
  DATA4: '12개월',
  HOUR: '시',
  MINUTE: '분',

  // Unit
  SIZE: '사이즈',
  LEFT: '좌',
  RIGHT: '우',
  CASE: '건',
  SHEETS: '장',
  PERSONS: '명',
  POINT: '점',
  COLOR: '색상',
  WON: '원',
  TEXT_SIZE: '자',

  // Range
  ALL: '전체',
  MAX: '최대',
  MIN: '최소',
  MORE_THAN: '이상',
  LESS_THAN: '이하',
  TOTAL: '총',
  SPOT: '순위',
  LAST_1_WEEK: '최근 1주일',
  LAST_1_MONTH: '최근 1개월',
  LAST_3_MONTH: '최근 3개월',
  LAST_6_MONTH: '최근 6개월',
  LAST_12_MONTH: '최근 12개월',
  MIN_DATE_RANGE: '1900-01-01',
  MAX_DATE_RANGE: '2999-12-31',
  // Classification
  GENERAL: '일반',
  GENDER: '성별',
  GANDER_MALE: '남자',
  GANDER_FEMALE: '여자',
  AGREE: '동의',
  DISAGREE: '미동의',
  NOT_AGREED: '동의',
  AGREED: '미동의',
  USE: '사용',
  NOT_USE: '미사용',
  USED: '사용함',
  NOT_USED: '사용 안 함',
  SETTING: '설정',
  NOT_SETTING: '설정안함',
  CERTIFIED_Y: '인증완료',
  CERTIFIED_N: '인증안함',
  MAY: '가능',
  UNABLE: '불가능',
  NOT_INTEGRATE: '통합하지 않음',
  INTEGRATE: '통합함',
  MEMBER_TYPE_MALL: '쇼핑몰회원',
  MEMBER_TYPE_SYNC_ID: '연동아이디회원',
  MEMBER_TYPE_OPEN_ID: '오픈아이디 회원',

  // Action
  INQUIRY: '조회',
  SEARCH: '검색',
  VIEW: '보기',
  ADVANCED_SEARCH: '상세검색',
  RESET: '초기화',
  CANCEL: '취소',
  CHANGE: '변경',
  ADD: '추가',
  COPY: '복사',
  COPY_ADDRESS: '주소복사',
  MODIFY: '수정',
  DELETE: '삭제',
  REINSTALL: '재설치',
  INPUT: '입력',
  WITHOUT_HYPHEN_INPUT: '- 없이 입력',
  SAVE: '저장',
  COMPLETE: '완료',
  CONFIRM: '확인',
  APPLY: '적용',
  CLOSE: '닫기',
  EXIT: '나가기',
  CLOSE_LAYER_POPUP: '레이어 팝업닫기',
  BASIC: '기본',
  RESULT: '조회결과',
  RESULT2: '검색결과 총',
  TOTAL_SEARCH_RESULT: '검색결과 총',
  SEARCH_RESULT: '검색결과',
  EXCUTE: '실행',
  DETAIL: '상세',
  DETAILS: '상세보기',
  EACH: '개',
  TEN_THOUSAND: '만',
  EACH_VIEW: '개 보기',
  DIRECT_INPUT: '직접 입력',
  ALL_CHECK: '전체선택',
  SELECT: '선택',
  NOT_SELECT: '선택 안함',
  MORE_VIEW: '더보기',
  SAVE_UPDATE: '변경사항 저장',
  REFRESH: '새로고침',
  SELECT_SHOPPING_MALL: '쇼핑몰 선택',
  NOT_SET: '설정안함',
  NEXT: '다음',
  PREV: '이전',
  STOP: '멈춤',
  PLAY: '재생',
  ID_REMEMBER: '아이디 기억하기',
  INTEGRATED_ACCOUNT: '통합계정 여부',
  CHANGE_PASSWORD: '비밀번호 변경',
  SEARCH_PASSWORD: '비밀번호 찾기',
  COPY_INSERT: '복사등록',
  INSERT: '등록',
  ALL_MENU: '전체메뉴',
  OPEN_ALL_MENU: '전체메뉴 열기',
  CLOSE_ALL_MENU: '전체메뉴 닫기',
  UPGRADE: '업그레이드',
  PAGE_SIZE_10: '10개 보기',
  PAGE_SIZE_30: '30개 보기',
  PAGE_SIZE_50: '50개 보기',
  PAGE_SIZE_100: '100개 보기',
  PAGE_SIZE_200: '200개 보기',
  PAGE_SIZE_500: '500개 보기',
  PAGE_SIZE_1000: '1000개 보기',
  PAGE_SIZE: '%d개 보기',

  // Noun
  MALL: '쇼핑몰',
  PRODUCT: '상품',
  SYSTEM: '시스템',
  PC_WEB: 'PC웹',
  MOBILE: '모바일',
  MOBILE_WEB: '모바일웹',
  MOBILE_APP: '모바일앱',
  PAYCO_KO: '페이코',
  NAVER_KO: '네이버',
  KAKAO_KO: '카카오',
  FACEBOOK_KO: '페이스북',
  KAKAO_SYNC_KO: '카카오싱크',
  LOGIN_MEMBER: '로그인 회원',
  SIMPLE: '간편',
  LINE_KO: '라인',
  QUICK_MENU: '퀵메뉴',
  NOTICE: '공지사항',
  ALERT: '알림',
  WARNING: '경고',
  HAZARD_INFORMATION: '위해정보',
  SAFETY_TRADE: '안전거래',
  PROMOTION: '프로모션',
  SALE: '할인',
  DOWNLOAD: '다운로드',
  DOWN: '다운',
  PAYCO: 'PAYCO',
  RESERVES: '적립금',
  SIR: '님',
  LOGIN: '로그인',
  LOGOUT: '로그아웃',
  IMAGE: '이미지',
  SERVICE_UNAVAILABLE: '(서비스 이용불가)',
  CREATE: '생성',
  SERVICE_POINT: '포인트',
  CLOSING: '종료',

  // Labels
  ID: '아이디',
  PASSWORD: '비밀번호',
  NAME: '이름',
  NICKNAME: '닉네임',
  EMAIL_ADDRESS: 'e메일주소',
  EMAIL: '이메일',
  PHONE_NUMBER: '전화번호',
  CELLPHONE_NUMBER: '휴대폰번호',
  ORDER_NO: '주문번호',
  TEMP_PASSWORD: '임시비밀번호',
  AUTH_NUMBER: '인증번호',
  POSITION: '직급',
  DEPARTMENT: '소속부서',
  DUTY: '직책',
  BELONG: '소속',
  CLASSES: '직위',
  REGISTER_DATE: '등록일',
  UPDATE_DATE: '수정일',
  REGISTER_UPDATE_DATE: '등록일/수정일',
  FINAL_UPDATE: '최종수정',
  UPDATE_ADMIN_NAME: '최종수정자',
  FINAL_UPDATE_DATE: '최종수정일',
  CATEGORY: '카테고리',
  CATEGORY_NO: '카테고리번호',
  CATEGORY_NAME: '카테고리명',
  NEW_CATEGORY: '신규 카테고리',
  FIND_FILE: '파일찾기',
  BAND_DESIGNATION: '대역지정',
  ALLOWED_COUNTRY: '허용국가',
  BLOCKED_COUNTRY: '차단국가',

  // Common : Alert, Confirm, Placeholder
  CHECK_SELECT: '을(를) 선택해주세요.',
  CHECK_SELECT_MALL: '쇼핑몰을 선택해주세요',
  CHECK_SELECT_PRODUCT: '상품을 선택해주세요',
  CHECK_INPUT_FILED: '을(를) 입력해주세요.',
  REQUIRED_INPUT: '필수입력',
  SAVE_CONFIRM: '변경된 내용을 저장하시겠습니까?',
  SAVE_SUCCESS: '변경사항이 저장 되었습니다.',
  NOT_DATA: '데이터가 존재하지 않습니다.',
  LOAD_DATA: '데이터를 불러오고 있습니다.',
  NO_RESULT: '검색결과가 없습니다.',
  MUST_SEARCH_TEMPLATE: '템플릿을 검색해 주세요.',
  MUST_SEARCH_HISTORY: '발송내역을 검색해주세요.',
  MUST_SEARCH_MEMBER: '회원을 검색해주세요.',
  MUST_SEARCH_DORMANT_MEMBER: '휴면회원을 검색해주세요.',
  MUST_SEARCH_WITHDRAWAL_MEMBER: '탈퇴회원을 검색해주세요.',
  WITHDRAWAL_MEMBER: '탈퇴회원',
  NUMBER_OR_HYPHEN_ONLY: '숫자와 - 만 입력 가능',
  CERTIFIED_CELLPHONE_NUMBER: '인증완료 된 휴대폰번호입니다 ',
  SENDED_CERTIFY_CODE: '휴대폰 번호로 인증코드가 전송되었습니다.',
  RE_CHECK_CELLPHONE_NUMBER: '휴대폰번호를 다시 확인 해 주세요.',
  INSERT_CERTIFY_CODE: '인증코드 입력',
  COMPLETE_CERTIFIED: '인증 완료되었습니다.',
  WRONG_CERTIFY_CODE: '인증코드가 잘못 입력되었습니다. 다시 확인해 주세요.',
  PLEASE_RIGHT_CERTIFY_CODE: '올바른 인증코드를 입력 해 주세요.',
  SAVE_CHANGES: '변경사항 저장',
  PLACEHOLDER_ONLY_NUMBER: '숫자만 입력',
  PLZ_PLACEHOLDER_ONLY_NUMBER: '숫자만 입력하세요',
  SERVER_ERROR: '서버 오류 발생',
  ALERT_WRONG_ACCESS: '잘못된 접근입니다.',
  ERROR_INPUT_FORMAT: '입력형식이 잘못되었습니다.',
  PLACEHOLDER_SEARCH: '검색어를 입력하세요.',
  NO_SEARCH_RESULTS: '검색결과가 없습니다.',
  ERROR_PERMISSION: '권한이 없습니다.',
  ALERT_REQUIRE_INPUT: '필수 입력값이 제공되지 않았습니다.',
  ALERT_ERROR: '오류가 발생하였습니다.',
  VERIFY_SMS: 'SMS인증',
  ERROR_DATE_SPAN: '시작일자는 종료일자보다 클 수 없습니다.',
  ERROR_FILE_SIZE: '{0}KB 이하의 이미지만 업로드 가능합니다.\r\n({1}KB)',
  ERROR_ONLY_IMAGE_FILE: '이미지 파일만 등록해주세요.',
  CONFIRM_UNSAVED: '사이트에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다.',
  ALERT_COPY_TO_CLIPBOARD: '{copyContentName} 주소 정보를 클립보드에 복사했습니다.\nctrl+v를 이용해서 사용하세요.',
  ALERT_INPUT_EMPTY: '{fieldName}을 입력해주세요.',
  ALERT_INPUT_NOT_VALID: '{fieldName}을 정확히 입력해주세요.',
  ALERT_NO_CHANGED: '변경사항이 없습니다.',
  ALERT_SAVED: '저장되었습니다.',
  ALERT_DELETED: '삭제되었습니다.',
  CONFIRM_DELETE: '삭제하시겠습니까?',
  ALERT_UPDATED: '수정되었습니다.',
  ALERT_NO_SELECTED: '{value}을 선택해주세요.',
  ALERT_NO_SELECTED_AG: '{value}를 선택해주세요.',
  ALERT_DISALLOW_EXTENSION: '허용하지 않는 확장자입니다.',
  ALERT_NO_OVER_END_DATE: '시작일자는 종료일자보다 클 수 없습니다.',
  ALERT_FAIL_COUNT: '{count}건 실패 되었습니다.',
  ALERT_MALL_FORBIDDEN: '해당 몰에 대한 접근권한이 없습니다.',
  ALERT_MAX_COUNT_OVER: '최대 {n}개 까지 선택 가능합니다.',
  ALERT_INVALID_OTP_VALUE: '인증번호가 일치하지 않습니다. 확인 후 정확히 입력해주세요.',
  ALERT_VALID_OTP_VALUE: '관리자 접속이 승인되었습니다.',
  ALERT_SEND_EMAIL: '이메일을 발송하였습니다.',
  ALERT_INVALID_EMAIL: '등록되지 않은 운영자이거나, 이메일 정보가 올바르지 않습니다.',

  // Login
  ERROR_LOGIN_INFO_NULL: '로그인 정보를 입력 해 주세요.',
  ALERT_CHECK_PLAN_TYPE:
    "사용하시는 shop by 요금제는 '{planType}'입니다.\n{planType} 전용 로그인 페이지로 접근해주세요.",
  VIEW_LOGIN_ADMIN_FAIL_MESSAGE:
    '아이디 또는 비밀번호를 잘못 입력하였거나 등록되지 않은 아이디입니다.\n10회 이상 잘못 입력하실 경우 아이디가 잠금 처리 됩니다.',
  VIEW_LOGIN_ERROR2: '안전한 이용을 위하여 자동입력방지 문자를 입력해 주세요.', // view.login.error2
  ID_PLACEHOLDER: '아이디 입력',
  PASSWORD_PLACEHOLDER: '비밀번호 입력',
  LISTEN_TO_VOICE: '음성으로 듣기',
  GUIDE_BY_LISTEN_TO_VOICE: '음성으로 안내되고 있습니다.',
  VIEW_IMAGE: '이미지로 보기',
  VIEW_LOGIN_AUTOFILL: '자동입력 방지문자 입력',

  // Policy
  PRIVACY_POLICY: '개인정보처리방침',
  TERMS_OF_USE: '이용 약관',
  BUSINESS_INFORMATION_VERIFICATION: '사업자정보확인',
  BUSINESS_INFORMATION_VERIFICATION_NCP: 'NCP 사업자 정보확인',

  // Search
  SEARCH_KEYWORD: '검색어',
  KEYWORD_PLACEHOLDER: '검색어',
  PLACEHOLDER_KEYWORD: '검색어 입력',
  VIEW_RESULT: '조회결과',

  // Users
  MEMBER: '회원',
  MEMBER_NO: '회원번호',
  MEMBER_GRADE_CHANGE: '회원등급 변경',
  MEMBER_GROUP_CHANGE: '회원그룹 변경',
  MEMBER_STATUS: '회원 현황',
  MEMBER_CONTENT: '회원 내역',
  MEMBER_JOIN: '회원 가입',
  MEMBER_LEAVE: '탈퇴 회원',
  PERIOD_USE: '기간사용',
  MEMBERS_WITHOUT_LOGIN_DATE: '로그인일자 없는 회원',
  SUSPENSION_OF_USE: '이용정지',
  FORCED_WITHDRAWAL: '강제탈퇴',

  // Promotion
  DISCOUNT_COUPON_PAYMENT: '할인쿠폰 지급',
  POINT_PAYMENT_OR_DEDUCTION: '적립금 지급/차감',
  NOTICE_PROMOTION: '프로모션 공지',
  PROMOTION_STATUS: '프로모션 현황',
  PROMOTION_DETAILS: '프로모션 내역',
  ISSUING_COUPON: '발행 중 쿠폰',
  VIEW_INDEX_COUPON_END7: '발행 종료 7일 전 쿠폰',
  ADDITIONAL_DISCOUNT_ON: '할인 중 추가할인',
  ADDITIONAL_DISCOUNT_END7: '진행 종료 7일 전 추가할인',
  EVENTS: '기획전 현황',
  EVENTS_CONTENT: '기획전 내역',
  EVENTS_ON: '진행중 기획전',
  EVENTS_END_3_DAY: '진행 종료 3일 전 기획전',
  REGISTER_ADMIN_NAME: '쿠폰등록자',

  // Board
  BOARD: '게시판',

  // Notice
  NO_NOTICE: '공지사항이 없습니다.',
  VIEW_ALL: '전체 보기',
  OPEN_NEW_WINDOW: '새 창 열기',
  VIEW_ALL_NOTICE: '공지사항 전체보기',

  ORDER_AND_DELIVERY: '주문/배송',

  // Order
  PRODUCT_STATUS: '상품 현황',
  WAIT_APPROVAL: '승인대기',
  WAIT_APPROVAL_MODIFICATION: '수정 후 승인대기',
  PRODUCT_FOR_SALE: '판매중인 상품',
  PRODUCT_OUT_OF_STOCK: '품절상품',
  PRODUCT_7_DAYS_BEFORE_END: '종료 7일전 상품',
  PRODUCT_10_LESS_STOCK: '재고 10개 이하 상품',
  PRODUCT_INQUIRY: '상품문의',
  SALES_STATUS: '판매 현황',
  NEW_ORDER: '신규주문',
  NEW_ORDER_GUIDE: '신규주문 건을 확인해 주세요.',
  SALES_STATUS_GUIDE: '판매현황 가이드',
  PRINT_SPECIFICATION: '거래명세서 출력',
  TASK_MESSAGE: '업무메시지 보기',
  TASK_MESSAGE_CLOSE: '업무메시지 닫기',
  CS_MESSAGE: 'CS처리 보기',
  CS_MESSAGE_CLOSE: 'CS처리 닫기',

  // Delivery
  DELIVERY_IN: '배송중',
  DELIVERY_COMPLETED: '배송완료',
  DELIVERY_PENDING: '배송보류',
  DELIVERY_PREPARE: '배송준비',
  ORDER_CANCEL: '취소주문',
  ORDER_RETURN: '반품주문',
  ORDER_EXCHANGE: '교환주문',
  PRODUCT_PREPARATION: '상품준비',
  PROCESSING_DELAY_STATUS: '처리지연 현황',
  PROCESSING_DELAY_STATUS_GUIDE: '처리지연 현황 가이드',
  DELIVERY_DELAY: '발송지연',
  DELIVERY_DELAY_RETURN: '반품지연',
  DELIVERY_DELAY_EXCHANGE: '교환지연',
  DELIVERY_DELAY_ANSWER: '답변지연',
  DELIVERY_DELAY_CANCEL: '취소지연',
  STATUS_DELIVERY_IN: '상품이 구매자에게 배송중입니다.',
  STATUS_DELIVERY_COMPLETED: '고객이 상품을 수령하였습니다.',

  // Claim
  DELIVERY_DELAY_HOLD: '배송보류지연',
  CLAIM_STATUS: '클레임 현황',
  CLAIM_STATUS_DESCRIPTION: '처리가 지연될 경우 고객 불편이 예상되니 빠른 처리바랍니다.',
  VIEW_INDEX_PRODUCT_INQUIRY_DELAY: '미답변 문의',
  VIEW_INDEX_RECENTLY_ONE_YEAR: '최근 1년',
  VIEW_INDEX_PRODUCT_INQUIRY_ALL: '전체 문의내역',
  ONE_ON_ONE_INQUIRY: '1:1 문의',
  ONE_ON_ONE_INQUIRY_CONTENT: '1:1 문의 내역',
  ONE_ON_ONE_INQUIRY_DELAY: '미답변 문의',
  ONE_ON_ONE_INQUIRY_ALL: '전체 문의내역',

  // admin
  ADMIN_ID: '운영자 아이디',
  ADMIN_NAME: '운영자명',
  ADMIN_ID_PLACEHOLDER: '운영자 아이디를 입력하세요.',
  ADMIN_NAME_PLACEHOLDER: '운영자명을 입력하세요.',
  VIEW_LOGIN_ADMIN_SUCCESS_MESSAGE:
    '운영자님의 e메일 주소로 비밀번호 변경 링크가 전송되었습니다. 메일 함을 확인해 주세요.',
  ADMIN_NAME_ERROR: '등록되지 않은 운영자 입니다.',
  ADMIN_ACCOUNT_HAS_BEEN_CHANGED: '운영자 계정 정보가 변경되었습니다.',
  VIEW_LOGIN_MAIL_MESSAGE1: '운영자 e메일로 비밀번호 변경 링크를 전송합니다.',
  VIEW_LOGIN_MAIL_MESSAGE2: '운영자 아이디를 모르실 경우 마스터 운영자에게 문의하세요.',
  MANAG_MY_ACCOUNT: '내 계정관리',
  MANAGER: '담당자',

  // footer
  FOOTER_CONTENT1: '상호: 엔에이치엔(주) 대표: 정우진', // 풋터 정의할때 common.ts 에서 분리해요
  FOOTER_CONTENT2: '주소: 13487 경기도 성남시 분당구 대왕판교로645번길 16 NHN 플레이뮤지엄',
  FOOTER_CONTENT3: '사업자 등록번호: 144-81-15549 통신판매업신고번호: 제2013-경기성남 1067호',
  DOWNLOAD_INFORMATION_PROTECT_GUIDE: '개인정보보호 가이드 다운로드',
  CONFIRM_UNSAVED_LEAVE: '페이지 이동 시 변경된 내용은 저장되지 않습니다.\n저장하지 않고 이동하시겠습니까?',

  // tooltip
  N_AMOUNT: '{0}원',
  N_PERCENT: '{0}%',
  N_COUNT: '{0}개',
  CLOSE_TOOLTIP: '툴팁 닫기',

  CHANGE_DATE: '변경일',
  CONTENTS: '내용',
  VIEW_DETAILS: '내용 확인하기',

  //navigation button
  TEMP_SAVE: '임시저장',
  LIST: '목록',
  SAVE2: '저장하기',

  // Classification
  SHIPPING: '주문/배송',
  DESIGN: '디자인',
  ADDITION: '부가서비스',
  MENU: '메뉴',
  MARKETING: '마케팅',
  STATISTICS: '통계',
  SETTLEMENT: '정산',

  //mode
  REGISTER: '등록',
  EDIT: '수정',

  MAIN: '대표',

  COMMON_FILE_SIZE_ERR: '{0}KB 이하의 이미지만 업로드 가능합니다.\r\n({1}KB)',
  COMMON_FILE_SIZE_ERR_IMG: '등록 실패 하였습니다.\n최대 {0}까지 등록가능합니다.',

  MANUAL_DOWNLOAD: '샵바이프로 매뉴얼 다운로드',

  // password 관련 메세지
  ALERT_INVALID_PASSWORD_LEAST_2: '영문, 숫자, 특수문자를 3종류 모두 조합하여 8~20자로 입력해주세요.',
  ALERT_INVALID_PASSWORD_LEAST_1: '영문, 숫자, 특수문자를 2종류 이상 조합하여 10~20자로 입력해주세요.',
  ALERT_INVALID_PASSWORD_SPECIAL: '특수문자는 !@#$%^&+=-_.()만 사용 가능합니다.',

  ALERT_CHANGED: '변경되었습니다.',

  THANK_YOU: '감사합니다.',

  SUCCESS_N: '성공 {count}건',
  FAIL_N: '실패 {count}건',

  JOIN_TYPE: '가입유형',

  ALERT_NOT_CHANGED: '변경된 사항이 없습니다.',

  CONSOLIDATED_AUTH: 'NHN커머스 통합계정으로 로그인',
  FAVORITES: '즐겨찾기',
  CREDIT_CHANGE: '요금제 변경',
  CREDIT_CHANGE_NEED_MASTER:
    '요금제 변경은 통합회원으로 전환된 대표운영자만 가능합니다. 통합회원 전환은 NHN커머스 홈페이지에서 가능합니다. ',
});
