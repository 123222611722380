





































import { Vue, Component } from 'vue-property-decorator';
import { LnbMenu } from '@/const/lnb.ts';
import { Getter } from 'vuex-class';

@Component
export default class Sitemap extends Vue {
  @Getter('lnb/getLnbMenu') lnbMenus!: LnbMenu[];

  /**
   * 메뉴 안에서 가장 긴 길이의 메뉴 이름을 선별하여, 그 메뉴 이름 길이 기준의 px 을 리턴함.
   */
  private getMenuHeaderPixelWidth(menu: LnbMenu[]): string {
    const menuNames = menu.map(({ name }) => name);
    const menuNameLengths = menuNames.map(({ length }) => length);
    const greaterIndex = menuNameLengths.indexOf(Math.max(...menuNameLengths));
    const greaterName = menuNames[greaterIndex];

    const greaterNamePixelWidth: number = greaterName
      .split('')
      .map((char): number => (char.charCodeAt(0) > 127 ? 2 : 1))
      .reduce((a, b) => a + b);

    return `${greaterNamePixelWidth * 8}px`;
  }

  private openWindow(uri: string) {
    window.open(uri, '_blank');
  }
}
