





















































































































/*
 * © NHN Commerce Corp. All rights reserved.
 * NHN Corp. PROPRIETARY/CONFIDENTIAL. Use is subject to license terms.
 *
 * @author Jaeha Choi
 * @since 2022-4-13
 */

import { Vue, Component, Ref } from 'vue-property-decorator';
// components
import PartnerName from '@/components/partner/register/PartnerName.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import EmailInput from '@/components/common/input/EmailInput.vue';
import PartnerMallSelectBox from '@/components/partner/register/PartnerMallSelectBox.vue';
import NewPartnerEntryContractYn from '@/components/partner/register/NewPartnerEntryContractYn.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import MerchandiserSelect from '@/components/common/input/data/MerchandiserSelect.vue';
// const
import { partnerEmailValidType } from '@/const/contents/partner';
// hooks
import {
  hasAdminName,
  hasEmail,
  hasMallNo,
  doubleCheckEntryContract,
  hasMd,
  hasFeeRate,
} from '@/views/contents/partner/hooks/partnerRegisterDoubleCheck';
import { handleFeeRateChange, handleFeeRateBeforeBlur } from '@/views/contents/partner/hooks/feeRateReplaceHook';
// nav
import { throwBottomNavigation } from '@/helpers/bottomNav';
// types
import { PostPartnersRequest } from 'ncp-api-supporter';
import { NavButton } from '@/types/bottomNav';

@Component({
  components: {
    PartnerName,
    TextInput,
    EmailInput,
    PartnerMallSelectBox,
    NewPartnerEntryContractYn,
    RadioGroup,
    MerchandiserSelect,
  },
})
export default class PartnerRegisterDetailInformation extends Vue {
  @Ref()
  private readonly partnerNameRef: PartnerName;
  @Ref()
  private readonly adminNameRef: PartnerName;
  @Ref()
  private readonly emailRef: EmailInput;
  @Ref()
  private readonly feeRateRef: TextInput;

  private readonly emailValidType = partnerEmailValidType;
  private readonly handleFeeRateChange = handleFeeRateChange;
  private readonly handleFeeRateBeforeBlur = handleFeeRateBeforeBlur;

  private onFeeRateBlur(feeRate: string) {
    this.feeRate = feeRate;
  }

  private useEntryContract = false;

  private partnerName = '';
  private adminName = '';
  private email = '';
  private selectedMallNo = '';
  private entryContractContent = '';
  private merchandiserNo = 0;
  private feeRate = '';
  private memo = '';

  private async validateRequirements() {
    if (await this.partnerNameRef.isDuplicatedPartnerName(this.partnerName)) return false;

    if (!hasAdminName(this.adminName, this.adminNameRef.focus)) return false;

    if (!hasEmail(this.email, this.emailRef.focus)) return false;

    if (!hasMallNo(this.selectedMallNo)) return false;

    if (!doubleCheckEntryContract(this.entryContractContent, this.useEntryContract)) return false;

    if (!hasMd(this.merchandiserNo)) return false;

    if (!hasFeeRate(this.feeRate, this.feeRateRef.focus)) return false;

    return true;
  }

  private async registerNewPartner() {
    const isSatisfiedRequirements = await this.validateRequirements();
    if (!isSatisfiedRequirements) return;

    const request: PostPartnersRequest['data'] = {
      partnerName: this.partnerName,
      adminName: this.adminName,
      adminEmail: this.email,
      mallNo: Number(this.selectedMallNo),
      contractContent: this.useEntryContract ? this.entryContractContent : '',
      merchandiserNo: this.merchandiserNo,
      commissionRate: Number(this.feeRate),
      memo: this.memo,
    };

    this.$emit('register-partner', request);
  }

  mounted() {
    const registerButton: NavButton = {
      type: 'right',
      key: 'registration',
      color: 'red',
      text: this.$t('PARTNER.REGISTER'),
    };

    throwBottomNavigation({
      buttons: [registerButton],
      onClick: this.registerNewPartner,
    });
  }
}
