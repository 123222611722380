import { DormantMemberQuery, MemberListContents, MemberListPopupType, WithdrawalMemberQuery } from '@/types/member';
import { addDay, getSelectBirthDayYears, getSelectDays, getSelectMonths, getToday } from '@/utils/dateFormat';
import { maskingEmail, maskingName } from '@/utils/common';
import { hiddenDescriptionRenderer, selectRenderer, warpHeaderTit } from '@/utils/grid/rendererUtils';
import { DefaultGridContainerOption, GridProp, OptColumn } from '@/types';
import { OptionData } from '@/helpers/type';
import { GetMembersRequest, MembersOrderBy, PersonalInfoAgreement } from 'ncp-api-supporter';
import { mall } from '@/utils/mall';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { FormatterProps } from '@/types/tui-grid/store/column';
import { GetMembersMemberNo } from 'ncp-api-supporter/dist/types/modules/member/member';
import { i18n } from '@/main';
import store from '@/store';

// dormant member
export const getDormantMemberSelectType = () => {
  return {
    searchType: [
      { name: 'MEMBER.DORMANT.MEMBER_ID', value: 'USER_ID' },
      { name: 'MEMBER.DORMANT.MEMBER_NAME', value: 'NAME' },
    ],
  };
};
export const getDormantMemberInitQuery = (): DormantMemberQuery => {
  const initQuery: DormantMemberQuery = {
    mallNo: mall.lastCreatedMall.mallNo,
    searchType: getDormantMemberSelectType().searchType[0].value,
    keyword: '',
    searchDateUsed: true,
    startDateTime: addDay(new Date(), -7) + ' 00:00:00',
    endDateTime: getToday() + ' 23:59:59',
    page: 1,
    pageSize: 30,
  };

  return { ...initQuery };
};

const fomatterDormantMemberDate = (date): string => {
  if (!date) {
    return date;
  }

  return date.slice(0, 10);
};

export const getDormantMemberGridProps = () => {
  return {
    columns: [
      {
        header: 'MEMBER.DORMANT.MEMBER_NO',
        name: 'memberNo',
        align: 'center',
        minWidth: 80,
      },
      {
        header: 'MEMBER.DORMANT.MEMBER_NAME_2',
        name: 'memberName',
        align: 'center',
        minWidth: 100,
        formatter: cell => {
          return maskingName(cell.value);
        },
      },
      {
        header: 'MEMBER.DORMANT.MEMBER_ID',
        name: 'memberId',
        align: 'center',
        minWidth: 150,
        formatter: cell => {
          return cell.value.toString().includes('@') ? maskingEmail(cell.value) : cell.value;
        },
      },
      {
        header: 'MEMBER.DORMANT.JOIN_DATE',
        name: 'joinedDateTime',
        align: 'center',
        minWidth: 150,
        formatter: cell => {
          return fomatterDormantMemberDate(cell.value);
        },
      },
      {
        header: 'MEMBER.DORMANT.LAST_LOGIN_DATE',
        name: 'lastLoginDateTime',
        align: 'center',
        minWidth: 150,
        formatter: cell => {
          return fomatterDormantMemberDate(cell.value);
        },
      },
      {
        header: 'MEMBER.DORMANT.DORMANT_DATE',
        name: 'dormantDateTime',
        align: 'center',
        minWidth: 150,
        formatter: cell => {
          return fomatterDormantMemberDate(cell.value);
        },
      },
    ],
    options: {
      rowHeaders: ['rowNum'],
      keyColumnName: 'memberNo',
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
  };
};

// membership rating
export const getMemberGradeGridProps = onGridCellEvent => {
  return {
    header: {
      columns: [
        {
          name: 'displayOrder',
          renderer: warpHeaderTit,
        },
        {
          name: 'reservesAutomaticPaymentUsed',
          renderer: warpHeaderTit,
        },
        {
          name: 'reserveRate',
          renderer: warpHeaderTit,
        },
        {
          name: 'reservesAutomaticPaymentAmount',
          renderer: warpHeaderTit,
        },
      ],
    },
    columns: [
      {
        header: 'MEMBER.GRADE.DISPLAY_ORDER',
        name: 'displayOrder',
        align: 'center',
        minWidth: 40,
        formatter: cell => {
          if (!cell.row.used) {
            return '-';
          }
          if (cell.row.order === 0) {
            return window.$t('MEMBER.GRADE.DISPLAY_ORDER_BASIC');
          }
          return cell.row.displayOrder;
        },
      },
      {
        header: 'MEMBER.GRADE.GRADE_NO',
        name: 'gradeNo',
        align: 'center',
        minWidth: 100,
        formatter: ({ row }) => {
          return row.no;
        },
      },
      {
        header: 'MEMBER.GRADE.GRADE_NAME',
        name: 'name',
        align: 'center',
        minWidth: 100,
        formatter: cell => {
          return `<a>${cell.value}</a>`;
        },
      },
      {
        header: 'MEMBER.GRADE.WHETHER_TO_USE',
        name: 'used',
        align: 'center',
        minWidth: 100,
        renderer: props => {
          props.selectOptions = [
            { text: window.$t('MEMBER.GRADE.USE'), value: true },
            { text: window.$t('MEMBER.GRADE.NOT_USE'), value: false },
          ];
          props.callback = event => {
            onGridCellEvent('used', event, props);
          };

          const renderer = selectRenderer(props);
          const row = props.grid.getRow(props.rowKey);

          if (row.order === 0) {
            renderer.getElement().setAttribute('disabled', 'true');
          }

          return renderer;
        },
      },
      {
        header: 'MEMBER.GRADE.AUTO_PAY',
        name: 'reservesAutomaticPaymentUsed',
        align: 'center',
        minWidth: 100,
        formatter: cell => {
          return cell.value ? window.$t('MEMBER.GRADE.USE') : window.$t('MEMBER.GRADE.NOT_USE');
        },
      },
      {
        header: 'MEMBER.GRADE.MEMBER_LENGTH',
        name: 'memberCount',
        align: 'center',
        minWidth: 150,
      },
      {
        header: 'MEMBER.GRADE.PAY_POINT',
        name: 'reserveRate',
        align: 'center',
        minWidth: 100,
      },
      {
        header: 'MEMBER.GRADE.REGULAR_POINT',
        name: 'reservesAutomaticPaymentAmount',
        align: 'center',
        minWidth: 100,
        formatter: ({ value }) => value.toLocaleString(),
      },
      {
        header: 'MEMBER.GRADE.COUPON',
        name: 'coupons',
        align: 'center',
        minWidth: 150,
        ellipsis: true,
        renderer: props => {
          if (!props.value || props.value.length === 0) {
            props.cellText = '';
          } else if (props.value.length === 1) {
            props.cellText = props.value[0].name;
          } else {
            props.cellText = window.$t('MEMBER.GRADE.COUPON_CELL_TEXT', {
              name: props.value[0].name,
              number: props.value.length - 1,
            });
          }

          props.hiddenDescription = '';
          if (props.value) {
            props.value.map(c => {
              if (props.hiddenDescription.length > 0) {
                props.hiddenDescription += '<br>';
              }

              const description = window.$t('MEMBER.GRADE.COUPON_CELL_DESC', c);
              if (c.statusType === 'ISSUE_END' || c.statusType === 'ISSUE_STOP') {
                props.hiddenDescription += `<span style="text-decoration: line-through;">${description}</span>`;
              } else {
                props.hiddenDescription += `<span>${description}</span>`;
              }
            });
          }

          return hiddenDescriptionRenderer(props);
        },
      },
      {
        header: 'MEMBER.GRADE.GRADE_DESC',
        name: 'description',
        align: 'center',
        minWidth: 150,
        ellipsis: true,
      },
      {
        header: 'DELETE',
        name: 'delete',
        align: 'center',
        minWidth: 100,
        formatter: () => {
          return `<a>${window.$t('DELETE')}</a>`;
        },
      },
    ],
    options: {
      rowHeaders: ['checkbox'],
      keyColumnName: 'displayOrder',
      pageOptions: {},
    },
  };
};

// withdrawal member
export const getWithdrawalMemberSelectType = () => {
  return {
    searchType: [
      { name: 'MEMBER.WITHDRAWAL.ALL', value: 'ALL' },
      { name: 'MEMBER.WITHDRAWAL.MEMBER_ID', value: 'USER_ID' },
      { name: 'MEMBER.WITHDRAWAL.MEMBER_NAME', value: 'NAME' },
      { name: 'MEMBER.WITHDRAWAL.MEMBER_NO', value: 'MEMBER_NO' },
      { name: 'MEMBER.WITHDRAWAL.MEMBER_EMAIL', value: 'EMAIL' },
      { name: 'MEMBER.WITHDRAWAL.MEMBER_MOBILE', value: 'MOBILE' },
    ],
    withdrawalType: [
      { name: 'MEMBER.WITHDRAWAL.WITHDRAWAL_TYPE', value: '' },
      { name: 'MEMBER.WITHDRAWAL.WITHDRAWAL_FORCED', value: 'true' },
      { name: 'MEMBER.WITHDRAWAL.WITHDRAWAL_SELF', value: 'false' },
    ],
    rejoinabilityType: [
      { name: 'MEMBER.WITHDRAWAL.REJOINABILIY', value: '' },
      { name: 'MEMBER.WITHDRAWAL.REJOIN_POSSIBLE', value: 'true' },
      { name: 'MEMBER.WITHDRAWAL.REJOIN_IMPOSSIBLE', value: 'false' },
    ],
    joinType: [
      { name: 'MEMBER.WITHDRAWAL.JOIN_TYPE', value: '' },
      { name: 'MEMBER.WITHDRAWAL.JOIN_NORMAL', value: 'MALL,SYNC_ID' },
      { name: 'PAYCO_KO', value: 'PAYCO' },
      { name: 'NAVER_KO', value: 'NAVER' },
      { name: 'KAKAO_KO', value: 'KAKAO' },
      { name: 'KAKAO_KO', value: 'KAKAO_SYNC' },
      { name: 'FACEBOOK_KO', value: 'FACEBOOK' },
    ],
  };
};
export const getWithdrawalMemberInitQuery = (): WithdrawalMemberQuery => {
  const initQuery: WithdrawalMemberQuery = {
    mallNo: mall.lastCreatedMall.mallNo,
    searchType: getWithdrawalMemberSelectType().searchType[0].value,
    keyword: '',
    searchDateUsed: true,
    startDateTime: addDay(new Date(), -7) + ' 00:00:00',
    endDateTime: getToday() + ' 23:59:59',
    page: 1,
    pageSize: 30,
  };

  return { ...initQuery };
};
export const getWithdrawalMemberGridProp = (): GridProp => {
  return {
    columns: [
      {
        header: 'MEMBER.WITHDRAWAL.MEMBER_NO',
        name: 'memberNo',
        align: 'center',
        minWidth: 80,
      },
      {
        header: 'MEMBER.WITHDRAWAL.MEMBER_NAME',
        name: 'memberName',
        align: 'center',
        minWidth: 100,
        formatter: cell => {
          return cell.row.gracePeriod ? '-' : cell.value;
        },
      },
      {
        header: 'MEMBER.WITHDRAWAL.MEMBER_ID',
        name: 'memberId',
        align: 'center',
        minWidth: 150,
        formatter: cell => {
          return cell.row.gracePeriod ? maskingEmail(cell.value) : cell.value;
        },
      },
      {
        header: 'MEMBER.WITHDRAWAL.WITHDRAWAL_FORCED',
        name: 'forced',
        align: 'center',
        minWidth: 150,
        formatter: cell => {
          return cell.value ? 'Y' : 'N';
        },
      },
      {
        header: 'MEMBER.WITHDRAWAL.REJOINABILIY',
        name: 'rejoinability',
        align: 'center',
        minWidth: 150,
        formatter: cell => {
          return cell.value
            ? window.$t('MEMBER.WITHDRAWAL.REJOIN_POSSIBLE')
            : window.$t('MEMBER.WITHDRAWAL.REJOIN_IMPOSSIBLE');
        },
      },
      {
        header: 'MEMBER.WITHDRAWAL.WITHDRAWAL_REASON',
        name: 'withdrawalReason',
        align: 'center',
        minWidth: 150,
        // formatter: cell => {
        //   return cell.value ? cell.value : '-';
        // },
        ellipsis: true,
        renderer: props => {
          // props.value = '고객서비스(상담, 포장 등) 불만스러운 부분이 ㅁ낳아서 탈퇴 신청 합니다. 고객서비스(상담, 포장 등) 불만스러운 부분이 ㅁ낳아서 탈퇴 신청 합니다. 고객서비스(상담, 포장 등) 불만스러운 부분이 ㅁ낳아서 탈퇴 신청 합니다.';

          props.cellText = props.value ? props.value : '-';

          // props.hiddenDescription = `<p style="width: 300px; white-space: pre-wrap">${props.value}</p>`;
          props.hiddenDescription = `<p>${props.value}</p>`;

          return hiddenDescriptionRenderer(props);
        },
      },
      {
        header: 'MEMBER.WITHDRAWAL.WITHDRAWAL_DATE',
        name: 'withdrawalDateTime',
        align: 'center',
        minWidth: 150,
        formatter: cell => {
          return fomatterDormantMemberDate(cell.value);
        },
      },
      {
        header: 'MEMBER.WITHDRAWAL.EMAIL',
        name: 'email',
        align: 'center',
        minWidth: 150,
        formatter: cell => {
          return cell.row.gracePeriod ? '-' : cell.value;
        },
      },
      {
        header: 'CELLPHONE_NUMBER',
        name: 'mobileNo',
        align: 'center',
        minWidth: 150,
        formatter: cell => {
          return cell.row.gracePeriod ? '-' : cell.value;
        },
      },
      {
        header: 'MEMBER.WITHDRAWAL.JOIN_TYPE',
        name: 'JOIN_TYPE',
        align: 'center',
        minWidth: 150,
        formatter: cell => {
          if (cell.row.gracePeriod) {
            return '-';
          }

          if (cell.row.memberType === 'OPEN_ID') {
            const joinType = getWithdrawalMemberSelectType().joinType.filter(
              j => j.value === cell.row.openIdProvider,
            )[0];
            return joinType && joinType.name ? joinType.name : '';
          }

          return window.$t('MEMBER.WITHDRAWAL.JOIN_NORMAL');
        },
      },
      {
        header: 'MEMBER.WITHDRAWAL.GRADE',
        name: 'memberGradeName',
        align: 'center',
        minWidth: 150,
        formatter: cell => {
          return cell.row.gracePeriod ? '-' : cell.value;
        },
      },
    ],
    options: {
      rowHeaders: ['checkbox', 'rowNum'],
      keyColumnName: 'memberNo',
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
  };
};

// member list
export const getMemberListSelectType = () => {
  return {
    searchType: [
      { name: 'ID', value: 'USER_ID' },
      { name: 'MEMBER.LIST.NAME', value: 'NAME' },
      { name: 'NICKNAME', value: 'NICKNAME' },
      { name: 'CELLPHONE_NUMBER', value: 'MOBILE' },
      { name: 'MEMBER.LIST.EMAIL', value: 'EMAIL' },
      { name: 'MEMBER.LIST.MEMBER_NO', value: 'MEMBER_NO' },
      { name: 'ORDER_NO', value: 'ORDER_NO' },
    ],
    types: [
      { name: 'ALL', value: 'MALL,OPEN_ID,SYNC_ID' },
      { name: 'MEMBER.LIST.BASIC_MEMBER', value: 'MALL,SYNC_ID' },
      { name: 'MEMBER.LIST.SIMPLE_LOGIN', value: 'OPEN_ID' },
    ],
    openIdProviders: [
      { name: 'ALL', value: 'PAYCO,NAVER,KAKAO,FACEBOOK,KAKAO_SYNC' },
      { name: 'PAYCO_KO', value: 'PAYCO' },
      { name: 'NAVER_KO', value: 'NAVER' },
      { name: 'KAKAO_KO', value: 'KAKAO,KAKAO_SYNC' },
      { name: 'FACEBOOK_KO', value: 'FACEBOOK' },
    ],
    use: [
      { name: 'NOT_USED', value: false },
      { name: 'USED', value: true },
    ],
    orderTerm: [
      { name: 'LAST_1_MONTH', value: 1 },
      { name: 'LAST_3_MONTH', value: 3 },
      { name: 'LAST_6_MONTH', value: 6 },
      { name: 'LAST_12_MONTH', value: 12 },
      { name: 'MEMBER.LIST.PERIOD_SELECT', value: -1 },
    ],
    gender: [
      { name: 'GENDER', value: '' },
      { name: 'MEMBER.LIST.NOT_SELECT', value: 'UNKNOWN' },
      { name: 'GANDER_MALE', value: 'MALE' },
      { name: 'GANDER_FEMALE', value: 'FEMALE' },
    ],
    signUpRoute: [
      { name: 'MEMBER.LIST.JOIN_WAY', value: '' },
      { name: 'MEMBER.LIST.PC_WEB', value: 'PC_WEB' },
      { name: 'MEMBER.LIST.MOBILE_WEB', value: 'MOBILE_WEB' },
    ],
    certified: [
      { name: 'MEMBER.LIST.MEMBER_CERTIFY_STATUS', value: '' },
      { name: 'MEMBER.LIST.COMPLETE_CERTIFY', value: true },
      { name: 'MEMBER.LIST.NOT_CERTIFY', value: false },
    ],
    smsAgreed: [
      { name: 'MEMBER.LIST.SMSAGREED', value: '' },
      { name: 'AGREE', value: true },
      { name: 'DISAGREE', value: false },
    ],
    emailAgreed: [
      { name: 'MEMBER.LIST.EMAILAGREED', value: '' },
      { name: 'AGREE', value: true },
      { name: 'DISAGREE', value: false },
    ],
    ageGroup: [
      { name: 'MEMBER.LIST.AGE', value: null },
      { name: 'MEMBER.LIST.AGE_COUNT1', value: 10 },
      { name: 'MEMBER.LIST.AGE_COUNT2', value: 20 },
      { name: 'MEMBER.LIST.AGE_COUNT3', value: 30 },
      { name: 'MEMBER.LIST.AGE_COUNT4', value: 40 },
      { name: 'MEMBER.LIST.AGE_COUNT5', value: 50 },
      { name: 'MEMBER.LIST.AGE_OVER_COUNT6', value: 60 },
      { name: 'MEMBER.LIST.SEARCH_BIRTH', value: -2 },
    ],
    lastLogin: [
      { name: 'MEMBER.LIST.LAST_LOGIN_DATE', value: '' },
      { name: 'USE', value: 'USE_LOGIN' },
      { name: 'MEMBER.LIST.NO_LOGIN_MEMBER', value: 'NOT_LOGIN' },
    ],
    dormantRelease: [
      { name: 'MEMBER.LIST.DORMANT_DATE', value: '' },
      { name: 'USE', value: 'USE_DORMANT_RELEASE' },
      { name: 'MEMBER.LIST.NO_DORMANT_MEMBER', value: 'NOT_DORMANT_RELEASE' },
    ],

    birthMonth: getSelectMonths(window.$t('MONTH').toString()),
    getBirthDay: (month: number): OptionData<number>[] => {
      const birthDay = [{ name: window.$t('ALL').toString(), value: null }];
      return [...birthDay, ...getSelectDays(window.$t('SUN_SHOR').toString(), month)];
    },
  };
};

export type MembersParams = Omit<GetMembersRequest['params'], 'loginCountFrom' | 'loginCountTo'> & {
  monthOfBirthday?: number;
  dayOfBirthday?: number;
  loginCountFrom?: number | string;
  loginCountTo?: number | string;
  orderBy: MembersOrderBy;
};

export const getMemberListInitQuery = (): MembersParams => {
  const INIT_INDEX = 0;
  return {
    mallNo: mall.lastCreatedMall.mallNo,
    searchType: getMemberListSelectType().searchType[INIT_INDEX].value,
    keyword: '',
    types: getMemberListSelectType().types[INIT_INDEX].value,
    openIdProviders: getMemberListSelectType().openIdProviders[INIT_INDEX].value,
    gradeNos: '',
    status: 'ACTIVE',
    certified: '',
    reserveBalanceFrom: null,
    reserveBalanceTo: null,
    loginCountFrom: null,
    loginCountTo: null,
    lastLoginDateFrom: '',
    lastLoginDateTo: '',
    gender: getMemberListSelectType().gender[INIT_INDEX].value,
    ageGroup: getMemberListSelectType().ageGroup[INIT_INDEX].value,
    smsAgreed: '',
    emailAgreed: '',
    signUpRoute: getMemberListSelectType().signUpRoute[INIT_INDEX].value,
    signUpDateFrom: '',
    signUpDateTo: '',
    page: 1,
    pageSize: 30,
    dormantReleaseFrom: '',
    dormantReleaseTo: '',
    orderBy: 'DEFAULT',
  };
};

export const getMemberListDisplayOption = (popupType: MemberListPopupType): DefaultGridContainerOption => {
  if (popupType === 'inquiry') {
    return { hasSettingButton: false, hasExcelDownloadButton: false };
  } else if (popupType === 'crm') {
    return { showsRightArea: false };
  } else {
    return { showsTopArea: true, showsRightArea: true };
  }
};

export const crmMemberColumn: OptColumn<MemberListContents>[] = [
  {
    header: 'MALL',
    name: 'mallName',
    align: 'center',
    minWidth: 150,
  },
  {
    header: 'MEMBER.LIST.MEMBER_NO',
    name: 'memberNo',
    align: 'center',
    minWidth: 120,
    formatter: ({ value }) => getAnchorHTML(value.toString()),
  },
  {
    header: 'MEMBER.LIST.MEMBER_NAME',
    name: 'name',
    align: 'center',
    minWidth: 370,
    formatter: ({ value }) => getAnchorHTML(value.toString()),
  },
  {
    header: 'MEMBER.LIST.ID',
    name: 'id',
    align: 'center',
    minWidth: 230,
    formatter: ({ value, row }) => {
      if (!row.openIdProvider) return value.toString();

      switch (row.openIdProvider) {
        case 'PAYCO':
          return `<span class="color-payco">${i18n.t('PAYCO_KO')}</span>`;
        case 'NAVER':
          return `<span class="color-naver">${i18n.t('NAVER_KO')}</span>`;
        case 'KAKAO':
          return `<span class="color-kakao">${i18n.t('KAKAO_KO')}</span>`;
        case 'FACEBOOK':
          return `<span class="color-facebook">${i18n.t('FACEBOOK_KO')}</span>`;
        case 'KAKAO_SYNC':
          return `<span class="color-kakao">${i18n.t('KAKAO_KO')}</span>`;
        default:
          return value.toString();
      }
    },
  },
  {
    header: 'MEMBER.LIST.EMAIL',
    name: 'email',
    align: 'center',
    minWidth: 150,
  },
  {
    header: 'MEMBER.LIST.MOBILE',
    name: 'mobile',
    align: 'center',
    minWidth: 150,
  },
  {
    header: 'MEMBER.LIST.CALL_NUMBER',
    name: 'phone',
    align: 'center',
    minWidth: 150,
  },
];

export const getMemberColumnExcludeModify = (isPremiumPlanType: boolean): OptColumn<MemberListContents>[] => {
  return [
    {
      header: 'MEMBER.LIST.MEMBER_NO',
      name: 'memberNo',
      align: 'center',
      minWidth: 120,
      formatter: ({ value }) => {
        return isPremiumPlanType ? value.toString() : getAnchorHTML(value.toString());
      },
    },
    {
      header: 'MALL',
      name: 'mallName',
      align: 'center',
      minWidth: 150,
    },
    {
      header: 'MEMBER.LIST.MEMBER_NAME',
      name: 'name',
      align: 'center',
      minWidth: 370,
      formatter: ({ value }) => {
        return isPremiumPlanType ? value.toString() : getAnchorHTML(value.toString());
      },
    },
    {
      header: 'MEMBER.LIST.ID',
      name: 'id',
      align: 'center',
      minWidth: 230,
      formatter: ({ value, row }) => {
        if (!row.openIdProvider) return value.toString();

        switch (row.openIdProvider) {
          case 'PAYCO':
            return `<span class="color-payco">${i18n.t('PAYCO_KO')}</span>`;
          case 'NAVER':
            return `<span class="color-naver">${i18n.t('NAVER_KO')}</span>`;
          case 'KAKAO':
            return `<span class="color-kakao">${i18n.t('KAKAO_KO')}</span>`;
          case 'FACEBOOK':
            return `<span class="color-facebook">${i18n.t('FACEBOOK_KO')}</span>`;
          case 'KAKAO_SYNC':
            return `<span class="color-kakao">${i18n.t('KAKAO_KO')}</span>`;
          default:
            return value.toString();
        }
      },
    },
    {
      header: 'MEMBER.LIST.NICKNAME',
      name: 'nickname',
      align: 'center',
      minWidth: 150,
    },
    {
      header: 'MEMBER.LIST.GRADE',
      name: 'grade',
      align: 'center',
      minWidth: 150,
      formatter: cell => {
        const value = cell.value as { name: string };
        return value.name ? value.name : '';
      },
    },
    {
      header: 'MEMBER.LIST.MOBILE',
      name: 'mobile',
      align: 'center',
      minWidth: 150,
    },
    {
      header: 'MEMBER.LIST.EMAIL',
      name: 'email',
      align: 'center',
      minWidth: 150,
    },
    {
      header: 'MEMBER.LIST.POINT',
      name: 'accumulationAmount',
      align: 'center',
      minWidth: 150,
      formatter: ({ value }) => {
        if (!value) return '0';
        const absValueLocaleString = Math.abs(Number(value)).toLocaleString();
        return Number(value) < 0 ? `-${absValueLocaleString}` : absValueLocaleString;
      },
    },
    {
      header: 'MEMBER.LIST.JOIN_DATA',
      name: 'createdDateTime',
      align: 'center',
      minWidth: 150,
    },
    {
      header: 'MEMBER.LIST.LAST_LOGIN',
      name: 'lastLoginDateTime',
      align: 'center',
      minWidth: 150,
    },
    {
      header: 'MEMBER.LIST.LOGIN_COUNT',
      name: 'loginCount',
      align: 'center',
      minWidth: 150,
      formatter: ({ value }) => value?.toString() || '0',
    },
    {
      header: 'MEMBER.LIST.DORMANT_DATE',
      name: 'releaseDate',
      align: 'center',
      minWidth: 150,
    },
    {
      header: 'MEMBER.LIST.JOIN_TYPE',
      name: 'type',
      align: 'center',
      minWidth: 150,
      formatter: ({ value, row }) => {
        if (value.toString() === 'MALL') return window.$t('MEMBER.LIST.BASIC_MEMBER');
        else {
          switch (row.openIdProvider) {
            case 'PAYCO':
              return `${i18n.t('PAYCO_KO')}`;
            case 'NAVER':
              return `${i18n.t('NAVER_KO')}`;
            case 'KAKAO':
              return `${i18n.t('KAKAO_KO')}`;
            case 'FACEBOOK':
              return `${i18n.t('FACEBOOK_KO')}`;
            case 'KAKAO_SYNC':
              return `${i18n.t('KAKAO_KO')}`;
            default:
              return `${i18n.t('MEMBER.LIST.SIMPLE_LOGIN')}`;
          }
        }
      },
    },
    {
      header: 'MEMBER.LIST.SMS_AGREED',
      name: 'smsAgreed',
      align: 'center',
      minWidth: 150,
      formatter: cell => {
        return cell.value ? 'Y' : 'N';
      },
    },
    {
      header: 'MEMBER.LIST.MAIL_AGREED',
      name: 'directMailAgreed',
      align: 'center',
      minWidth: 150,
      formatter: cell => {
        return cell.value ? 'Y' : 'N';
      },
    },
    {
      header: 'MEMBER.LIST.COLLECTION_AND_USE',
      name: 'collectionAndUseRequiredAgreed',
      align: 'center',
      minWidth: 150,
      formatter: ({ row }: FormatterProps<MemberListContents>) => {
        const personalInfoAgreement = row.personalInfoAgreement as PersonalInfoAgreement;

        return personalInfoAgreement && personalInfoAgreement.collectionAndUseOptionalAgreed ? 'Y' : 'N';
      },
    },
    {
      header: 'MEMBER.LIST.PROCESS_CONSIGNMENT',
      name: 'processConsignmentAgreed',
      align: 'center',
      minWidth: 150,
      formatter: ({ row }: FormatterProps<MemberListContents>) => {
        const personalInfoAgreement = row.personalInfoAgreement as PersonalInfoAgreement;

        return personalInfoAgreement && personalInfoAgreement.processConsignmentAgreed ? 'Y' : 'N';
      },
    },
    {
      header: 'MEMBER.LIST.THIRD_PARTY_PROVISION',
      name: 'thirdPartyProvisionAgreed',
      align: 'center',
      minWidth: 150,
      formatter: ({ row }: FormatterProps<MemberListContents>) => {
        const personalInfoAgreement = row.personalInfoAgreement as PersonalInfoAgreement;

        return personalInfoAgreement && personalInfoAgreement.thirdPartyProvisionAgreed ? 'Y' : 'N';
      },
    },
  ];
};

export const getMemberListGridOption = (isCrmPopup: boolean): GridProp<MemberListContents> => {
  const isPremiumPlanType: boolean = store?.getters['admin/getAdmin']?.plan === 'PREMIUM';
  const memberColumn: OptColumn<MemberListContents>[] = isPremiumPlanType
    ? getMemberColumnExcludeModify(isPremiumPlanType)
    : [
        ...getMemberColumnExcludeModify(isPremiumPlanType),
        {
          header: 'MODIFY',
          name: 'memberRegistrationModify',
          align: 'center',
          minWidth: 100,
          formatter: () => getAnchorHTML(window.$t('MODIFY')),
        },
      ];
  return {
    columns: isCrmPopup ? crmMemberColumn : memberColumn,
    options: {
      keyColumnName: 'memberNo',
      rowHeaders: ['checkbox'],
    },
  };
};

export const getDefaultGetMembersMemberNo = (): GetMembersMemberNo => {
  return {
    memberNo: 0,
    memberName: '',
    mallNo: 0,
    memberId: '',
    nickname: '',
    gradeNo: 0,
    gradeName: '',
    gradeReevaluationDate: '',
    groupNames: [],
    certificated: false,
    certificatedDate: '',
    adultCertificated: false,
    adultCertificatedDate: '',
    dateOfBirth: '',
    gender: '',
    phoneNo: '',
    mobileNo: '',
    email: '',
    refundAccountBank: '',
    refundAccountNo: '',
    refundAccountOwnerName: '',
    zipCode: '',
    streetAddress: '',
    streetAddressDetail: '',
    landNumberAddress: '',
    landNumberAddressDetail: '',
    signUpDateTime: '',
    signUpRoute: '',
    lastLoginDateTime: '',
    lastLoginIpAddress: '',
    memberType: '',
    blacklisted: false,
    appPushAgreed: false,
    smsAgreed: false,
    directMailAgreed: false,
    memberStatus: '',
    recommendId: '',
    comment: '',
    personalInfoAgreement: {
      thirdPartyProvisionAgreed: false,
      processConsignmentAgreed: false,
      collectionAndUseAgreed: false,
      collectionAndUseOptionalAgreed: false,
      collectionAndUseRequiredAgreed: false,
      upper14AgeAgreed: false,
      customTerms: '',
    },
    openIdDependents: [],
    openIdProvider: '',

    directMailAgreedDateTime: '',
    certificationType: '',
    loginCount: 0,
    releaseDateTime: '',
    smsAgreedDateTime: '',
  };
};
export const getMemberRegistrationSelectType = () => {
  return {
    yearList: getSelectBirthDayYears(window.$t('YEAR').toString()),
    monthList: getSelectMonths(window.$t('MONTH').toString()),
    getDayList: month => {
      if (!month) {
        return [];
      }

      return getSelectDays(window.$t('MEMBER.REGISTRATION.SELECT_DAY').toString(), month);
    },
  };
};

export const getMemberOrderBySelectOptions: OptionData<MembersOrderBy>[] = [
  {
    name: 'MEMBER.LIST.ORDER_BY_DEFAULT',
    value: 'DEFAULT',
  },
  {
    name: 'MEMBER.LIST.ORDER_BY_LAST_LOGIN_DESC',
    value: 'LAST_LOGIN_DESC',
  },
  {
    name: 'MEMBER.LIST.ORDER_BY_LAST_LOGIN',
    value: 'LAST_LOGIN',
  },
  {
    name: 'MEMBER.LIST.ORDER_BY_RELEASE_DESC',
    value: 'RELEASE_DESC',
  },
  {
    name: 'MEMBER.LIST.ORDER_BY_RELEASE',
    value: 'RELEASE',
  },
  {
    name: 'MEMBER.LIST.ORDER_BY_LOGIN_COUNT_DESC',
    value: 'LOGIN_COUNT_DESC',
  },
  {
    name: 'MEMBER.LIST.ORDER_BY_LOGIN_COUNT',
    value: 'LOGIN_COUNT',
  },
];
