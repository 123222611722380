export default Object.freeze({
  BASIC_INFO: '기본정보',

  TABLE_TITLE: '파트너 등록 세부사항',
  PARTNER_NAME: '파트너명',
  PARTNER_NAME_PLACEHOLDER: '파트너명 입력',
  EXISTS_BTN: '중복확인',
  EXISTS_OK: '사용할 수 있는 파트너명입니다.',
  EXISTS_NG: '이미 등록된 파트너명입니다.',
  ADMIN: '대표 운영자',
  MALL_CONTRACT_MANAGER: '쇼핑몰 계약 담당자',
  ADMIN_NAME: '대표 운영자명',
  ADMIN_NAME_PLACEHOLDER: '운영자명 입력',
  ADMIN_NAME_HINT: '휴대폰 본인인증을 위해 반드시 실명으로 기입해주시기 바랍니다.',
  EMAIL: 'e메일 주소',
  TRADE_MALL: '거래 쇼핑몰',
  LEASE_AGREEMENT: '입점 계약서 사용',
  POP_NAME: '입점 계약서',
  DISABLE: '사용 안 함',
  ENROLLMENT: '등록',
  CHANGE: '변경',
  MD_NAME: '담당MD명',
  MD_NOT_HAVE: 'MD권한을 가진 운영자가 없습니다. 상품담당자(MD)권한을 가지는 운영자를 생성해주세요.',
  COMMISSION_RATE: '수수료율',
  MEMO: '전달 메모',
  REGISTER: '파트너 등록',

  PARTNER_NO: '파트너 번호',
  COMPANY_NAME: '회사명',
  REGISTRATION_NO: '사업자 등록번호',
  SELLER_TYPE: '과세형태',
  REPRESENTATIVE_NAME: '대표자명',
  BUSINESS_TYPE: '업종',
  BUSINESS_CONDITION: '업태',
  REPRESENT_PHONE_NO: '대표전화번호',
  FAX_NO: '팩스번호',
  BUSINESS_DECLARATION_NO: '통신판매업신고번호',

  SELLER_TAXATION_TYPE_NORMAL: '일반과세자',
  SELLER_TAXATION_TYPE_SIMPLE: '간이과세자',
  SELLER_TAXATION_TYPE_VAT_FREE: '부가세 면세사업자',
  SELLER_TAXATION_TYPE_CORPORATION: '법인사업자',
  SELLER_TAXATION_TYPE_FREE_CORPORATION: '면세법인사업자',
  SELLER_TAXATION_TYPE_ETC: '기타',

  ERR_NAME_NULL: '파트너명을 입력해주세요.',
  ERR_ADMIN_NAME_NULL: '대표 운영자명을 입력해주세요.',
  ERR_EMAIL_FORMAT: 'e메일 형식을 확인해주세요.',
  ERR_MALL_NO_NULL: '거래 쇼핑몰을 선택해주세요.',
  ERR_MD_NO_NULL: '담당MD를 선택해주세요.',
  ERR_RATE_NULL: '수수료율을 입력해주세요.',
  ERR_CONTRACT_NULL: '입점 계약서를 등록해주세요',

  ALERT_BASIC_NOT_REGISTERED:
    'basic의 경우 파트너 등록이 불가합니다. pro 요금제 이용 시, 추가 서비스 이용이 가능합니다. 요금제를 변경 하시겠습니까?',
  REGISTER_CONFIRM: '파트너를 등록하시겠습니까?',
});
