export default Object.freeze({
  // COMMON_HEADER_
  SHOW_NOTIFICATION: '알림 확인',

  VIEW_ALL: '전체보기',
  CLOSE_ALL_MENU: '전체메뉴 닫기',

  MANAGE_MY_ACCOUNT: '내 계정관리',
  LEFT_MENU_OPEN_SETTING: '좌측 메뉴 열림 설정',
  CHANGE_PASSWORD: '비밀번호 변경',
  CHANGE_PASSWORD_GUIDE: '비밀번호 변경안내',
  WORKSPACE_SHORTCUT: 'NHN커머스 워크스페이스',

  MY_SHOPPING_MALL: '내쇼핑몰',
  CANCELLATION_REQUEST: '취소신청',
  RETURN_REQUEST: '반품신청',
  EXCHANGE_REQUEST: '교환신청',
  OUT_OF_STOCK_PRODUCTS: '품절상품',

  SHOP_BY_MOBILE_APP_DOWNLOAD: 'shop by 모바일 어드민 App 다운로드',
  MANAGE_SHOPPING_MALLS_WITH_YOUR_SMARTPHONE_ANYTIME_ANYWHERE:
    '언제, 어디서나 스마트폰으로 쇼핑몰을 편하게 관리해보세요.',
  QR_CODE_DOWNLOAD: 'QR코드로 다운받기',
  MOVE_LINK_DOWNLOAD: '링크로 이동하여 다운받기',
});
