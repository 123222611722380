import { OptionData, GroupOption } from '@/helpers/type';
import { GetSalesRequest, GetSettlementsRequest, OrderFriendsPartnerType } from 'ncp-api-supporter';
import { mall } from '@/utils/mall';
import { getBeforeDate, getFirstAndEndDay } from '@/utils/dateFormat';
import { warpHeaderTit } from '@/utils/grid/rendererUtils';
import { formatCurrency } from '@/utils/numberFormat';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { i18n } from '@/main';
import {
  PARTNER_KEYWORD_TYPE,
  PARTNER_NOTICE_SEARCH_TYPE,
  PARTNER_PERIOD_TYPE,
  PARTNER_STATUS,
  REGISTERED_PRODUCT,
} from '@/const/partner';
import { i18nForPartnerList } from '@/views/contents/partner/list/PartnerList';
import { ValueOf } from '@/types';

const { startYmd } = getFirstAndEndDay();

export const partnerSalesQuery = (): GetSalesRequest['params'] => ({
  mallNo: mall.lastCreatedMall.mallNo,
  startDateTime: startYmd,
  endDateTime: getBeforeDate(),
  page: 1,
  size: 30,
});

export const partnerSettlementsQuery = (): GetSettlementsRequest['params'] => ({
  mallNo: mall.lastCreatedMall.mallNo,
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  page: 1,
  size: 30,
});

export const partnerSalesType: OptionData<OrderFriendsPartnerType>[] = [
  { name: 'PARTNER.SALES.PARTNER_TYPE_DOMESTIC', value: 'DOMESTIC' },
  { name: 'PARTNER.SALES.PARTNER_TYPE_OVERSEAS', value: 'OVERSEAS' },
];

// --- 그리드 옵션
export const getPartnerSalesGridProps = () => ({
  header: {
    columns: [
      {
        name: 'partnerDiscountAmt',
        renderer: warpHeaderTit,
      },
      {
        name: 'settlementAmt',
        renderer: warpHeaderTit,
      },
    ],
  },
  columns: [
    {
      header: 'PARTNER.SALES.HEADER_SEARCH_YMD',
      name: 'createDate',
      align: 'center',
      minWidth: 60,
    },
    {
      header: 'PARTNER.SALES.HEADER_ORDER_CNT',
      name: 'orderCnt',
      align: 'center',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'PARTNER.SALES.HEADER_REFUND_CNT',
      name: 'refundCnt',
      align: 'center',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'PARTNER.SALES.HEADER_SALES_AMT',
      name: 'salesAmt',
      align: 'center',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'PARTNER.SALES.HEADER_COMMISSION_AMT',
      name: 'commissionAmt',
      align: 'center',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'PARTNER.SALES.HEADER_DELIVERY_AMT',
      name: 'deliveryAmt',
      align: 'center',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'PARTNER.SALES.HEADER_PARTNER_DISCOUNT_AMT',
      name: 'partnerDiscountAmt',
      align: 'center',
      minWidth: 140,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'PARTNER.SALES.HEADER_DEDUCTED_AMT',
      name: 'partnerDeliveryAdjustAmt',
      align: 'center',
      minWidth: 180,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'PARTNER.SALES.HEADER_SETTLEMENT_AMT',
      name: 'settlementAmt',
      align: 'center',
      minWidth: 180,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'DOWNLOAD',
      name: 'download',
      align: 'center',
      minWidth: 60,
      formatter: () => getAnchorHTML(i18n.t('DOWNLOAD')),
    },
  ],
  options: {
    pageOptions: {
      page: 1,
      perPage: 30,
      totalCount: 0,
    },
  },
  displayOptions: {
    showsRightArea: true,
    hasExcelDownloadButton: false,
    hasSettingButton: false,
    pageSizeKey: 'size',
    gridWrapperClass: 'box-cont inner-table-wrap type-2',
    titleWrapperClass: 'view-title',
  },
});

export const getPartnerSettlementsGridProps = () => ({
  columns: [
    {
      header: 'SETTLEMENT.LIST.HEADER_DATE',
      name: 'settlementDate',
      align: 'center',
      minWidth: 100,
    },
    {
      header: 'SETTLEMENT.LIST.HEADER_PERIOD_TYPE',
      name: 'settlementPeriodType',
      align: 'center',
      minWidth: 80,
    },
    {
      header: 'SETTLEMENT.LIST.HEADER_STATE',
      name: 'settlementState',
      align: 'center',
      minWidth: 60,
    },
    {
      header: 'SETTLEMENT.LIST.HEADER_PERIOD',
      name: 'settlementPeriod',
      align: 'center',
      minWidth: 200,
    },
    {
      header: 'SETTLEMENT.LIST.HEADER_PARTNER_NAME',
      name: 'partnerName',
      align: 'center',
      minWidth: 100,
    },
    {
      header: 'SETTLEMENT.LIST.HEADER_PARTNER_TYPE',
      name: 'partnerType',
      align: 'center',
      minWidth: 100,
    },
    {
      header: 'SETTLEMENT.LIST.HEADER_SALES_AMT',
      name: 'salesAmt',
      align: 'right',
      minWidth: 100,
    },
    {
      header: 'SETTLEMENT.LIST.HEADER_COMMISSION_AMT',
      name: 'commissionAmt',
      align: 'right',
      minWidth: 100,
    },
    {
      header: 'SETTLEMENT.LIST.HEADER_DELIVERY_AMT',
      name: 'deliveryAmt',
      align: 'right',
      minWidth: 100,
    },
    {
      header: 'SETTLEMENT.LIST.HEADER_PARTNER_DISCOUNT_AMT',
      name: 'partnerDiscountAmt',
      align: 'right',
      minWidth: 180,
    },
    {
      header: 'SETTLEMENT.LIST.HEADER_DEDUCTED_AMT',
      name: 'deductedAmt',
      align: 'right',
      minWidth: 100,
    },
    {
      header: 'SETTLEMENT.LIST.HEADER_AMT',
      name: 'settlementAmt',
      align: 'right',
      minWidth: 180,
    },
    {
      header: 'SETTLEMENT.LIST.HEADER_BANK_NAME',
      name: 'bankName',
      align: 'center',
      minWidth: 100,
    },
    {
      header: 'SETTLEMENT.LIST.HEADER_TRADE_BANK_ACCOUNT',
      name: 'tradeBankAccount',
      align: 'center',
      minWidth: 100,
    },
    {
      header: 'SETTLEMENT.LIST.HEADER_TRADE_BANK_DEPOSITOR_NAME',
      name: 'tradeBankDepositorName',
      align: 'center',
      minWidth: 100,
    },
    {
      header: 'SETTLEMENT.LIST.HEADER_EXCEL_BTN',
      name: 'download',
      align: 'center',
      minWidth: 100,
      formatter: () => getAnchorHTML(i18n.t('DOWNLOAD')),
    },
  ],
  displayOptions: {
    showsRightArea: true,
    hasExcelDownloadButton: false,
    hasSettingButton: false,
    pageSizeKey: 'size',
  },
  options: {
    rowHeaders: ['rowNum'],
    columnOptions: {
      minWidth: 50,
      resizable: true,
    },
    displayRowCount: 30,
    isInitialRequest: true,
    useClientSort: false,
    pageOptions: {
      page: 1,
      perPage: 30,
      totalCount: 0,
    },
  },
});

export type PeriodType = 'MONTH' | 'WEEK' | 'DAY';
export type StateType = 'PENDING' | 'CONFIRM';

export const getPeriodTypeName = (periodType: PeriodType): string => {
  return i18n.t(`SETTLEMENT.LIST.PERIOD_TYPE_${periodType}`) as string;
};
export const getStateName = (settlementState: StateType): string => {
  return i18n.t(`SETTLEMENT.LIST.STATE_${settlementState}`) as string;
};

// ***** 파트너 등록 *****
// 입점 계약서 사용여부 버튼옵션
export const entryContractOptions: GroupOption<boolean> = {
  name: 'entryContractYn',
  data: [
    {
      id: 'entryContractN',
      name: 'entryContractN',
      value: false,
      display: 'NOT_USED',
    },
    {
      id: 'entryContractY',
      name: 'entryContractY',
      value: true,
      display: 'USE',
    },
  ],
};

export const partnerEmailValidType = {
  id: 'partner-email-id',
  domain: 'partner-email-domain',
};

export type PartnerType = 'ALL' | 'SHOP' | 'PARTNER'; //파트너사 선택 : 전체 / 쇼핑몰자체상품 / 파트너사

export const getPartnerListSelectBox = () => ({
  partnerSearchType: Object.entries(PARTNER_KEYWORD_TYPE).map(([key, value]) => ({
    name: i18nForPartnerList(key),
    value,
  })),
  partnerPeriodType: Object.entries(PARTNER_PERIOD_TYPE).map(([key, value]) => ({
    name: i18nForPartnerList(key),
    value,
  })),
});

export const getPartnerListRadioGroup = () => ({
  partnerStatus: {
    name: 'partnerStatus',
    data: Object.entries(PARTNER_STATUS).map(([key, value]) => ({
      id: `partnerStatus_${key}`,
      name: `partnerStatus_${key}`,
      value,
      display: i18nForPartnerList(key),
    })),
    nonI18nConvert: true,
  },
  registeredProduct: {
    name: 'registeredProduct',
    data: Object.entries(REGISTERED_PRODUCT).map(([key, value]) => ({
      id: `registeredProduct_${key}`,
      name: `registeredProduct_${key}`,
      value,
      display: i18nForPartnerList(key),
    })),
    nonI18nConvert: true,
  },
});

export const getPartnerNoticeSelectBox = () => ({
  searchType: Object.entries(PARTNER_NOTICE_SEARCH_TYPE).map(([key, value]) => ({
    name: `PARTNER.NOTICE.${key}`,
    value,
  })),
});

/**
 * 유효성 검증 정보
 */
export const PARTNER_VALIDATION_INFO = {
  COMPANY_NAME: {
    size: 20,
    validType: 'no-emoji',
  },
  PARTNER_NAME: {
    size: 63,
    validType: 'no-emoji',
  },
  REGISTRATION_NO: {
    size: 10,
    validType: 'number',
  },
} as const;
export type PartnerValidType = ValueOf<typeof PARTNER_VALIDATION_INFO>['validType'];
