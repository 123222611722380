




















































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import {
  getAuthorityGroupCheckBoxOption,
  getAuthorityGroupRadioBoxOption,
  getAuthorityGroupSelectType,
  MENU_AUTHORITY_TYPE,
  MenuDataType,
} from '@/const/contents/configuration/authorityGroup';
import NoticeBox from '@/components/common/NoticeBox.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { GetAuthorityGroupsByGroupNoResponse } from 'ncp-api-supporter';

@Component({ components: { NoticeBox, SelectBox, CheckboxGroup, RadioGroup } })
export default class AuthorityGroupModifyMenu extends Vue {
  @PropSync('basicData', { required: true }) private basicDataSync!: GetAuthorityGroupsByGroupNoResponse;
  @PropSync('menuList', { required: true }) private menuListSync!: MenuDataType[];
  @PropSync('prevMenuAuthorityTypeMap', { required: true }) private prevMenuAuthorityTypeMapSync: Map<
    string,
    MENU_AUTHORITY_TYPE
  >;
  private selectType = getAuthorityGroupSelectType();
  private checkBoxOption = getAuthorityGroupCheckBoxOption();
  private radioBoxOption = getAuthorityGroupRadioBoxOption();

  private noticeList = [this.$t('CONFIGURATION.AUTHORITY_GROUP.NOTICE_MENU_AUTHORITY')];

  private viewMenuType = this.selectType.viewMenuType[0].value;
  private authorityMenuType = this.selectType.authorityMenuType[0].value;

  private allChecked = false;

  get allowsMarketingDirection(): string {
    return this.basicDataSync.allowsMarketingDirection + '';
  }
  set allowsMarketingDirection(value: string) {
    this.basicDataSync.allowsMarketingDirection = value === 'true';

    if (this.basicDataSync.allowsMarketingDirection) {
      const menu = this.menuListSync.filter(m => m.isProductMenu)[0];
      menu.authorityType = MENU_AUTHORITY_TYPE.READ_WRITE;
      this.changeMenuAuthority(menu);
    }
  }

  private changeData() {
    this.$emit('changeData');
  }

  // click reset authority
  private resetAuthority(): void {
    this.$emit('resetMenuAuthority');
  }

  // select view menu type
  private changeViewMenuType(): void {
    this.menuListSync.map(m => {
      if (m.depth <= this.viewMenuType) {
        m.show = true;
      } else {
        m.show = false;
      }
    });
  }

  // click apply checked menu authority
  private applyCheckedMenuAuthority(): void {
    const arr = this.menuListSync.filter(m => m.checked);

    if (arr.length === 0) {
      alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_CHECK_MENU'));
      return;
    }

    if (!this.authorityMenuType) {
      alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_SELECT_MENU_AUTHORITY'));
      return;
    }

    arr.map(m => {
      m.authorityType = this.authorityMenuType as MENU_AUTHORITY_TYPE;
      this.changeMenuAuthority(m);
    });

    const productMenu = this.menuListSync.filter(m => m.isProductMenu)[0];
    if (productMenu.authorityType !== MENU_AUTHORITY_TYPE.READ_WRITE) {
      this.basicDataSync.allowsMarketingDirection = false;
    }

    this.changeData();
  }

  // toggle check
  private toggleCheck(all: boolean): void {
    let checked = true;

    const checkedLength = this.menuListSync.filter(m => m.checked).length;

    if (all) {
      if (checkedLength === this.menuListSync.length) {
        checked = false;
      }

      this.menuListSync.map(m => {
        m.checked = checked;
      });
    } else {
      if (checkedLength < this.menuListSync.length) {
        checked = false;
      }
    }

    this.allChecked = checked;
  }

  // toggle expand sub menu
  private toggleExpandMenu(menu: MenuDataType): void {
    if (this.isSubMenuExpand(menu)) {
      this.setSubMenuExpand(menu, false);
    } else {
      this.setSubMenuExpand(menu, true);
    }
  }

  // button tag class setting
  private getMenuButtonClass(menu: MenuDataType): {} {
    const menuButtonClass = {
      'group-plus': false,
      'group-minus': false,
    };

    if (menu.children && menu.children.length > 0) {
      if (this.isSubMenuExpand(menu)) {
        menuButtonClass['group-plus'] = false;
        menuButtonClass['group-minus'] = true;
      } else {
        menuButtonClass['group-plus'] = true;
        menuButtonClass['group-minus'] = false;
      }
    }

    return menuButtonClass;
  }

  // check sub menu expand
  private isSubMenuExpand(menu: MenuDataType): boolean {
    if (menu.children && menu.children.length > 0) {
      for (let i = 0; i < menu.children.length; i++) {
        const m1 = menu.children[i];

        if (m1.show) {
          return true;
        }

        if (m1.children && m1.children.length > 0) {
          if (this.isSubMenuExpand(m1)) {
            return true;
          }
        }
      }
    }

    return false;
  }

  // set expand or none expand all sub menu
  private setSubMenuExpand(menu: MenuDataType, show: boolean): void {
    if (menu.children && menu.children.length > 0) {
      for (let i = 0; i < menu.children.length; i++) {
        const m1 = menu.children[i];

        m1.show = show;

        if (!show && m1.children && m1.children.length > 0) {
          this.setSubMenuExpand(m1, show);
        }
      }
    }
  }

  // change menu authority
  private changeMenuAuthority(menu: MenuDataType, fromRadioGroup?: boolean): void {
    if (fromRadioGroup) {
      const prevAuthorityKey = `${menu.menuNo}-${menu.depth}`;
      const prevAuthorityType: MENU_AUTHORITY_TYPE | undefined = this.prevMenuAuthorityTypeMapSync.get(
        prevAuthorityKey,
      );
      if (menu.children && menu.children.length > 0) {
        if (!confirm(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_CHANGE_PARENT_MENU_AUTHORITY').toString())) {
          this.$nextTick(() => {
            menu.authorityType = prevAuthorityType;
          });
          return;
        }
        prevAuthorityType && this.prevMenuAuthorityTypeMapSync.set(prevAuthorityKey, menu.authorityType);
      }
    }

    // 모든 자식 메뉴 권한을 동일하게 변경
    let arrChildMenu = [menu];
    while (arrChildMenu.length > 0) {
      const target = arrChildMenu.shift();

      if (target.children && target.children.length > 0) {
        arrChildMenu = arrChildMenu.concat(target.children);
      }

      target.authorityType = menu.authorityType;
    }

    let parentMenu = menu;

    // 0-depth 의 메뉴면 부모 메뉴가 존재하지 않으므로 할 필요 없음
    if (!parentMenu) {
      return;
    }

    while (parentMenu.parent) {
      parentMenu = parentMenu.parent;
    }

    // 부모 메뉴 설정 다시 설정
    this.$emit('setParentMenuAuthority', parentMenu);
  }

  private isDisabledByMd(menu: MenuDataType): boolean {
    if (!this.basicDataSync.allowsMarketingDirection) {
      return false;
    }

    let parentMenu = menu;
    while (parentMenu.parent) {
      parentMenu = parentMenu.parent;
    }

    return parentMenu.isProductMenu;
  }
}
