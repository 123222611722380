import {
  GetContractsEntryContractsRequest,
  GetContractsRequest,
  GetPartnersInfoByNoRequest,
  PartnersInfoByNo,
  PostContractsEntryContractsRequest,
  PutContracts,
  PutContractsContractNoApprovalRequest,
  PutContractsContractNoDisapprovalRequest,
  PutContractsRequest,
  PutPartnersInfoRequest,
} from 'ncp-api-supporter';

// GET
export const getPartnerInfoRequest = (partnerNo: string): GetPartnersInfoByNoRequest => {
  return {
    pathParams: {
      partnerNo: partnerNo,
    },
  };
};

export const getContractsRequest = (partnerNo: number): GetContractsRequest => {
  return {
    params: {
      partnerNo,
    },
  };
};

export const getContractsEntryContractsRequest = (
  contractNo: string,
  agreed?: boolean,
): GetContractsEntryContractsRequest => {
  return {
    pathParams: {
      contractNo,
    },
    params: {
      agreed,
    },
  };
};

// POST
export const postContractsEntryContractsRequest = (
  contractNo: string,
  contractContents: string,
  changeReason?: string,
): PostContractsEntryContractsRequest => {
  return {
    pathParams: {
      contractNo,
    },
    data: {
      contractContents,
      changeReason,
    },
  };
};

export const postPartnersPartnerNoResendEmailRequest = (partnerNo: string, contractNo?: number) => {
  return {
    pathParams: {
      partnerNo,
    },
    data: {
      contractNo,
    },
  };
};

// PUT
export const putContractsContractNoApprovalRequest = (contractNo: string): PutContractsContractNoApprovalRequest => {
  return {
    params: {
      contractNo,
    },
  };
};

export const putContractsContractNoDisapprovalRequest = (
  contractNo: string,
): PutContractsContractNoDisapprovalRequest => {
  return {
    params: {
      contractNo,
    },
  };
};

export const putContractsRequest = (partnerNo: number, contracts: PutContracts[]): PutContractsRequest => {
  return {
    data: {
      partnerNo,
      contracts,
    },
  };
};

export const putPartnersByPartnerNoRequest = (
  partnerNo: string,
  partnerInfo: PartnersInfoByNo,
): PutPartnersInfoRequest => {
  const { email: csManagerEmail } = partnerInfo.csManager;

  return {
    pathParams: {
      partnerNo: partnerNo,
    },
    data: {
      officeAddress: {
        zipCode: partnerInfo.office.zipCode,
        streetAddress1: partnerInfo.office.address,
        streetAddress2: partnerInfo.office.detailAddress,
        jibunAddress1: partnerInfo.office.jibun,
        jibunAddress2: partnerInfo.office.jibunDetail,
        city: partnerInfo.office.city,
        stateOrRegion: partnerInfo.office.stateOrRegion,
      },
      partnerName: partnerInfo.partnerName,
      companyName: partnerInfo.companyName,
      sellerTaxationType: partnerInfo.sellerTaxationType,
      faxNo: partnerInfo.faxNo,
      representative: {
        representativeName: partnerInfo.representative.name,
        representPhoneNo: partnerInfo.representative.phoneNo,
        representEmail: partnerInfo.representative.email,
      },
      business: {
        businessRegistrationNo: partnerInfo.business.registrationNo,
        businessCondition: partnerInfo.business.condition,
        businessType: partnerInfo.business.type,
        onlineMarketingBusinessDeclarationNo: partnerInfo.business.onlineMarketingDeclarationNo,
      },
      settlement: {
        settlementManagerName: partnerInfo.settlementManager.name,
        settlementManagerPhoneNo: partnerInfo.settlementManager.phoneNo,
        settlementManagerEmail: partnerInfo.settlementManager.email,
      },
      bank: {
        tradeBank: partnerInfo.tradeBank.bank === 'DIRECT' ? null : partnerInfo.tradeBank.bank,
        tradeBankName: partnerInfo.tradeBank.bankName,
        tradeBankAccount: partnerInfo.tradeBank.account,
        tradeBankDepositorName: partnerInfo.tradeBank.depositorName,
      },
      masterAdmin: {
        masterJobPositionName: partnerInfo.masterAdmin.jobPositionName,
        masterDepartmentName: partnerInfo.masterAdmin.departmentName,
        masterJobName: partnerInfo.masterAdmin.jobDutyName,
        masterPhoneNo: partnerInfo.masterAdmin.phone,
        masterMobileNo: partnerInfo.masterAdmin.mobile,
        externalAccess: partnerInfo.masterAdmin.externalAccessEnabled,
        permittedIpAddresses: partnerInfo.masterAdmin.permittedIpAddresses,
      },
      permittedIpAddress: partnerInfo.permittedIpAddress,
      privacyManager: {
        privacyManagerName: partnerInfo.privacyManager.name,
        privacyManagerPhoneNo: partnerInfo.privacyManager.phoneNo,
      },
      manager: {
        managerName: partnerInfo.manager.name,
        managerJobDuty: partnerInfo.manager.jobDuty,
        managerDepartment: partnerInfo.manager.department,
        managerJobPosition: partnerInfo.manager.jobPosition,
        managerPhoneNo: partnerInfo.manager.phoneNo,
        managerEmail: partnerInfo.manager.email,
      },
      sampleUrl: partnerInfo.sampleUrl,
      internationalCode: partnerInfo.internationalCode,
      csManager: {
        csManagerName: partnerInfo.csManager.name,
        csManagerPhoneNo: partnerInfo.csManager.phoneNo,
        csManagerOperationInfo: partnerInfo.csManager.operationInfo,
        csManagerEmail: csManagerEmail.split('@').some((item) => !item) ? '' : csManagerEmail,
      }
    },
  };
};

// DELETE
export const deletePartnerRequest = (partnerNo: string) => {
  return {
    pathParams: {
      partnerNo,
    },
  };
};
